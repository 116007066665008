import './style.css'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader'

/**
 * Base
 */
// Debug

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0x353652);

{
  const near = 1;
  const far = 8;
  const color = 'lightblue';
  scene.fog = new THREE.Fog(color, near, far);
  scene.background = new THREE.Color(color);
}

{
  const color = 0xFFFFFF;
  const intensity = 2;
  const light = new THREE.DirectionalLight(color, intensity);
  light.position.set(-1, 2, 4);
  scene.add(light);
}


const lightAmbient = new THREE.AmbientLight(0x404040); // soft white light
scene.add(lightAmbient);

const objectPosition = {
  start: -3,
  end: 0.5,
  startedAnimation: false
}

let jellyFish = null


/**
 * Models
 */
const gltfLoader = new GLTFLoader()
const dracoLoader = new DRACOLoader()
const fbxLoader = new FBXLoader()
dracoLoader.setDecoderPath('/draco/')
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null
let loadingObject = true
fbxLoader.load(
  '/models/jellyfish/qualle8.fbx',
  (object) => {
    jellyFish = object
    mixer = new THREE.AnimationMixer(jellyFish)
    const action = mixer.clipAction(jellyFish.animations[1])
    action.play()
    jellyFish.scale.set(0.003, 0.003, 0.003)
    jellyFish.position.set(objectPosition.start, objectPosition.start + 1, objectPosition.start)
    scene.add(jellyFish)
    objectPosition.startedAnimation = true
    loadingObject = false

    const loadingMessage = document.querySelector('div.loading')
    if(loadingMessage) loadingMessage.style.display = 'none'
  }
)

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(2, 2, 2)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
  const elapsedTime = clock.getElapsedTime()
  const deltaTime = elapsedTime - previousTime
  previousTime = elapsedTime

  // mixer update (for animation)
  if (mixer) mixer.update(deltaTime)

  // Update controls
  controls.update(deltaTime)

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)

  if (objectPosition.startedAnimation && objectPosition.end > jellyFish.position.x) {
    jellyFish.position.x += 0.01
    jellyFish.position.y += 0.01
    jellyFish.position.z += 0.01
  }
}

tick()